import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [{
		path: "/",
		name: "word",
		component: () => import('@/pages/layout/index.vue'),
		children: [{
				path: "/menulist",
				name: "菜单列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/index.vue")
			},
			{
				path: "/level",
				name: "二级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/level/index.vue")
			},
			{
				path: "/pubbtn",
				name: "按钮列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/menu/pubbtn/index.vue")
			},
			{
				path: "/",
				name: "正式工会列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/word/index.vue")
			},
			{
				path: "/result",
				name: "选举结果列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/result/index.vue")
			},
			{
				path: "/config",
				name: "系统配置",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/config/index.vue")
			},
			{
				path: "/staff",
				name: "用户列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/staff/index.vue")
			},
			{
				path: "/joinlist",
				name: "职工入会列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/joinlist/index.vue")
			},
			{
				path: "/audit",
				name: "企业建会申请列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/audit/index.vue")
			},
			{
				path: "/uselist",
				name: "会员列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/word/uselist/index.vue")
			},
			{
				path: "/news",
				name: "资讯列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/news/index.vue")
			},
			{
				path: "/opinion",
				name: "建言献策列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/opinion/index.vue")
			},
			{
				path: "/newstype",
				name: "资讯分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/news/type/index.vue")
			},
			{
				path: "/banner",
				name: "轮播列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/banner/index.vue")
			},
			{
				path: "/userlist",
				name: "管理员列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/user/index.vue")
			},
			{
				path: "/rolelist",
				name: "角色列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/role/index.vue")
			},
			{
				path: "/info",
				name: "用户资料",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/info/index.vue")
			},
			{
				path: '/:pathMatch(.*)*',
				name: '404',
				component: () => import('../views/404/index.vue')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue')
	}

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	//  记录位置
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				top: 0
			}
		}
	},
	routes
})

export default router